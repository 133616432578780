<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <UserNav activePage="adminblog" activeTab="2"></UserNav>
                <!--begin::Content-->
                <div class="flex-row-fluid ml-lg-8">
                    <!--begin::Content-->
                    <div class="flex-row-fluid ">
                        <div class="card card-custom gutter-b card-shadowless">
                            <!--begin::Header-->
                            <div class="card-header border-0 pt-5">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label font-weight-bolder text-dark fs-15">
                                        <h3 class="font-weight-bolder text-dark fs-15">Blog List</h3>
                                    </span>
                                    <!-- <span class="text-dark mt-3 font-weight-bold font-size-sm">
                                        More than 400+ new members
                                    </span> -->
                                </h3>
                                <div class="card-toolbar">
                                    <ul class="nav nav-pills nav-pills-sm nav-dark-75 btn-sm">
                                        <li class="nav-item">
                                            <router-link :to="{ name: 'createblog' }"
                                                class="nav-link btn-ingomublue py-2 px-4 active">New</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-3 pb-0">
                                <div class="table-responsive">
                                    <div class="list list-hover">
                                        <div class="col-xl-12 mt-5 mb-5">
                                            <b-form-group class="mb-0">
                                                <b-input-group size="sm">
                                                    <b-form-input id="filter-input" v-model="filter" type="search"
                                                        placeholder="Type to Search"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </div>
                                        <b-table id="my-table" :items="blogList" :fields="fields" :per-page="perPage"
                                            :current-page="currentPage" show-empty thead-class="hidden_header"
                                            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                            class="table table-borderless table-vertical-center">
                                            <template #cell(icon)="data" class="pl-0 py-4">
                                                <div class="symbol symbol-50 symbol-light-success mr-1"
                                                    v-if="data.item.online == true">
                                                    <span class="symbol-label">
                                                        <img src="media/svg/misc/checkmark-success.svg"
                                                            class="h-50 align-self-center" alt="" />
                                                    </span>
                                                </div>
                                                <div class="symbol symbol-50 symbol-light-danger mr-1" v-else>
                                                    <span class="symbol-label svg-icon-danger">
                                                        <img src="media/svg/misc/checkmark-error.svg"
                                                            class="h-50 align-self-center" alt="" />
                                                    </span>
                                                </div>
                                            </template>
                                            <template #cell(title)="data" class="pl-0">
                                                <span class="text-dark font-weight-bolder mb-1 font-size-lg"
                                                    v-if='data.item.title != ""'>
                                                    {{ data.item.title }}
                                                </span>
                                            </template>
                                            <template #cell(datetime)="data">
                                                <span class="text-dark font-weight-500">
                                                    {{ shortFormatDate(data.item.date) }}
                                                </span>
                                            </template>
                                            <template #cell(status)="data">
                                                <button @click="changeStatus(data.item.id, false)"
                                                    v-if="data.item.online == true"
                                                    class="label label-lg label-inline label-light-success">
                                                    Online
                                                </button>
                                                <button @click="changeStatus(data.item.id, true)" v-else
                                                    class="label label-lg label-inline label-light-danger">
                                                    Offline
                                                </button>
                                                <a :href="'https://ingomu.com/blog/' + data.item.slug" target="_blank"
                                                    v-if="data.item.online == true"
                                                    class="label label-lg label-inline label-light-info ml-5">
                                                    Preview
                                                </a>
                                                <a :href="'https://ingomu.com/admin/blog/preview/' + data.item.slug"
                                                    target="_blank" v-else
                                                    class="label label-lg label-inline label-light-info ml-5">
                                                    Preview
                                                </a>
                                            </template>
                                            <template #cell(buttons)="data">
                                                <router-link :to="{
                                                    name: 'editblog',
                                                    params: {
                                                        blogId: data.item.id,
                                                    }
                                                }"
                                                    class="btn btn-text-primary btn-hover-light-primary font-weight-bold mr-2 btn-sm">
                                                    Edit</router-link>
                                            </template>
                                            <template #empty="scope">
                                                <h5>There are no blogs yet!</h5>
                                            </template>
                                        </b-table>
                                        <div class="mt-10">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                                align="left">
                                            </b-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Body-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
    name: "Blog",
    components: {
        UserNav
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "icon", label: "" },
                { key: "title", label: "Title" },
                { key: "datetime", label: "Date" },
                { key: "status", label: "Status" },
                { key: "buttons", label: "", tdClass: "text-right" },
            ],
            filter: null,
            filterOn: [],
        }
    },
    mixins: [formateDateMixin],
    methods: {
        changeStatus(blogId, status) {
            this.$store.dispatch("updateBlogStatus", { blogId: blogId, status: status })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    },
    mounted() {
        this.$store.dispatch("getBlogList");
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: 1 });
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 1 });
    },
    computed: {
        ...mapGetters(["blogList"]),
        rows() {
            return this.blogList.length;
        }
    },
};
</script>